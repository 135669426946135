import { useConfig } from '@/context/config'
import { useEffect } from 'react'

export default function Root() {
  const { config } = useConfig()
  useEffect(() => {
    // Root コンテンツにアクセスされた際はカップルLPにリダイレクトする
    if (process.browser) {
      window.location.href = config.coupleLpUrl
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export async function getServerSideProps() {
  return {
    props: {
      pageInfo: {
        title: 'トップ',
      },
      user: [],
    },
  }
}
